import { v4 as uuidv4 } from "uuid";

const CACHE_USER_KEY = "CACHE_USER_KEY";

export const setLoggedInUserData = (userdata) => {
  const sessionId = uuidv4();
  localStorage.setItem(CACHE_USER_KEY, JSON.stringify(userdata));
  localStorage.setItem("USER_SESSION_ID", sessionId)
};

export const getLoggedInUserData = () => {
  let data = JSON.parse(localStorage.getItem(CACHE_USER_KEY));
  if (data) {
    return data;
  }
};

export const clearCache = () => {
  const bannerDismissValue = localStorage.getItem("isBannerDismissed");
  const isModalDismissed = localStorage.getItem("isModalDismissed");
  localStorage.clear();
  localStorage.setItem("isBannerDismissed", bannerDismissValue);
  localStorage.setItem("isModalDismissed", isModalDismissed);
  window.cacheCleared = true;
};

export const getLoggedInUserName = () => {
  let userData = getLoggedInUserData();
  if (userData) {
    return userData.Name;
  }
};

export const getLoggedInUserAccessToken = () => {
  let userData = getLoggedInUserData();
  if (userData) {
    return userData.Token;
  }
};

export const updateLoggedInUserAccessToken = (newAuthToken, accessToken) => {
  var userData = { ...getLoggedInUserData() };
  if (userData) {
    userData.Token = newAuthToken;
    userData.AccessToken = accessToken;
    setLoggedInUserData(userData);
  }
};

export const getLoggedInUserRefreshToken = () => {
  let userData = getLoggedInUserData();
  if (userData) {
    return userData.RefreshToken;
  }
};

export const logOutUser = () => {
  return new Promise((resolve, reject) => {
    clearCache();
    resolve(true);
  });
};
