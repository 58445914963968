import { LicenseManager } from "ag-grid-enterprise";
import axios from "axios";
import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import App from "./components/App";
import config from "./config";
import i18n from "./config/locale/i18n";
import store from "./reducers/store";

import "react-toastify/dist/ReactToastify.css";

const licenseKey = config.agGridLicenseKey;
LicenseManager.setLicenseKey(licenseKey);

if (config.demoApp) {
  import("./network/start-server")
    .then(({ default: startServer }) => {
      startServer({ isTestSetup: false });
    })
    .catch((error) => {
      console.error("Failed to load startServer:", error);
      alert("Failed to load startServer");
    });
}

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common["Content-Type"] = "application/json";
const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
      <ToastContainer hideProgressBar={true} />
    </I18nextProvider>
  </Provider>
);
