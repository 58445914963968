import classNames from "classnames";
import i18next from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import {
  Alert,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import Widget from "../../../../components/Widget";
import {
  changePassword,
  clearForgotPassword,
  receiveToken,
} from "../../../../modules/authentication/actions/auth";
import { checkPasswordVisibility } from "../../../../utils/common";
import style from "../reset-password/ResetPassword.module.scss";
import { withRouter } from "@hocs/withRouter";

class ChangePassword extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  static isAuthenticated(token) {
    if (!token) return;
    return true;
  }

  constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);
    const defaultEmail = params.get(decodeURIComponent("email"));

    this.state = {
      code: "",
      validCode: true,
      newPassword: "",
      confirmPassword: "",
      validPassword: true,
      validConfirmPassword: true,
      email: defaultEmail,
    };

    this.changeVerificationCode = this.changeVerificationCode.bind(this);
    this.changeNewPassword = this.changeNewPassword.bind(this);
    this.changeConfirmPassword = this.changeConfirmPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  changeVerificationCode(event) {
    const checkCode = event.target.value;
    this.setState({ code: checkCode, validCode: checkCode.length >= 6 });
  }

  changeNewPassword = (event) => {
    this.setState({
      newPassword: event.target.value,
      validPassword: checkPasswordVisibility(event.target.value),
    });
    this.state.confirmPassword && this.checkConfirmPassword(event.target.value);
  };

  checkConfirmPassword = (newPassword) => {
    this.setState({
      validRetypeNewPassword: this.state.confirmPassword === newPassword,
    });
  };

  changeConfirmPassword = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      validConfirmPassword: this.state.newPassword === event.target.value,
    });
  };

  changePassword(e) {
    e.preventDefault();
    this.props.dispatch(
      changePassword({
        ConfirmationCode: this.state.code,
        Username: this.state.email,
        Password: this.state.confirmPassword,
      })
    );
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get("token");
    if (token) {
      this.props.dispatch(receiveToken(token));
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: "AUTH_PAGE_UNLOADED" });
    this.props.dispatch(clearForgotPassword());
  }

  render() {
    const { t, isFetching } = this.props;
    const icommon = i18next.getFixedT(null, "common");
    const { from } = this.props.location.state || {
      from: { pathname: "/app" },
    };

    // cant access login page while logged in
    if (ChangePassword.isAuthenticated(localStorage.getItem("token"))) {
      return <Navigate to={from} />;
    }

    const {
      code,
      validCode,
      newPassword,
      validPassword,
      confirmPassword,
      validConfirmPassword,
      email,
    } = this.state;

    const disableChangePasswordBtn =
      !code ||
      !validCode ||
      !newPassword ||
      !validPassword ||
      !confirmPassword ||
      !validConfirmPassword ||
      newPassword !== confirmPassword;

    return (
      <div className="auth-container">
        <Row className="row-transparent-bg">
          <Col xl={12}>
            <Container className="padding-yaxis-5em min-height-100vh">
              <Widget
                className="widget padding-30px bs4-mx-auto"
                title={
                  <h4 className="bs4-mt-3 bs4-mb-2 bs4-ml-2 orange-text">
                    {t("changePassword")}
                  </h4>
                }
                logo={
                  <a
                    href="https://www.montycloud.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      loading="lazy"
                      className="logo"
                      src="/images/DAY2Logo.jpg"
                      alt="MontyCloud"
                    ></img>
                  </a>
                }
              >
                {this.props.passwordChanged ? (
                  <div className="bs4-mt-4">
                    <Alert className="alert-sm" color="success">
                      {t("message1")}
                    </Alert>
                    <Link className="mt-sm bs4-mt-2 bs4-mx-auto" to="/login">
                      {t("login")}
                    </Link>
                  </div>
                ) : (
                  <form className="mt" onSubmit={this.changePassword}>
                    <Alert
                      className="alert-sm mc-text-sm-regular"
                      color={this.props.errorMessage ? "danger" : "success"}
                    >
                      {this.props.errorMessage
                        ? this.props.errorMessage
                        : t("message2")}
                    </Alert>
                    <FormGroup row className="bs4-mt-4">
                      <Col>
                        <InputGroup>
                          <Input
                            className="form-control mc-text-base-regular"
                            value={email}
                            readOnly
                            type="email"
                            required
                            name="email"
                            placeholder={t("email")}
                          />
                          <span className="input-group-text">
                            <i className="fa fa-envelope-o" />
                          </span>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="bs4-mt-4">
                      <Col>
                        <InputGroup>
                          <Input
                            className={classNames(
                              "form-control mc-text-base-regular ",
                              { [style.invalidInput]: !validCode }
                            )}
                            value={code}
                            onChange={this.changeVerificationCode}
                            type="text"
                            required
                            name="code"
                            placeholder={t("verificationCode")}
                          />
                          <span className="input-group-text">
                            <i className="fi flaticon-locked-5" />
                          </span>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="bs4-mt-4">
                      <Col>
                        <InputGroup>
                          <Input
                            className={classNames(
                              "form-control mc-text-base-regular",
                              { [style.invalidInput]: !validPassword }
                            )}
                            value={newPassword}
                            onChange={this.changeNewPassword}
                            type="password"
                            required
                            name="new-password"
                            placeholder={t("newPassword")}
                          />
                          <span className="input-group-text">
                            <i className="fi flaticon-locked-4" />
                          </span>
                        </InputGroup>
                      </Col>
                    </FormGroup>

                    <FormGroup row className="bs4-mt-4">
                      <Col>
                        <InputGroup>
                          <Input
                            className={classNames(
                              "form-control mc-text-base-regular",
                              { [style.invalidInput]: !validConfirmPassword }
                            )}
                            value={confirmPassword}
                            onChange={this.changeConfirmPassword}
                            type="password"
                            required
                            name="confirm-password"
                            disabled={!newPassword || !validPassword}
                            placeholder={t("confirmPassword")}
                          />
                          <span className="input-group-text">
                            <i className="fi flaticon-locked-4" />
                          </span>
                        </InputGroup>
                        {!validConfirmPassword && (
                          <small className={`bs4-mb-0 bs4-mt-1 ${style.PwdText}`}>
                            {t("passwordsMismatchMsg")}
                          </small>
                        )}
                      </Col>
                    </FormGroup>
                    <small
                      className={classNames(
                        style.PwdText,
                        "mc-text-xsm-regular"
                      )}
                    >
                      {t("passwordMsg")}
                    </small>

                    <div className="clearfix bs4-mt-2">
                      <div className="btn-toolbar float-right">
                        <button
                          type="submit"
                          href="/app"
                          disabled={
                            isFetching ? true : disableChangePasswordBtn
                          }
                          className="btn btn-primary btn-md"
                        >
                          {isFetching ? "Loading..." : t("changePassword")}
                        </button>
                      </div>
                    </div>
                    <h6 className="clearfix bs4-mt-4">
                      {t("register2")}
                      <Link className="mt-sm bs4-m-1" to="/login">
                        {t("login")}
                      </Link>
                      {icommon("here")}
                    </h6>
                  </form>
                )}
              </Widget>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    passwordChanged: state.auth.passwordChanged,
    isAuthenticated: state.tokenReducer.isAuthenticated,
    errorMessage: state.auth.errorMessage,
    resetPasswordEmail: state.auth.resetPasswordEmail,
  };
}

export default withRouter(
  connect(mapStateToProps)(withTranslation(["forgotPassword"])(ChangePassword))
);
