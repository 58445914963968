export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const AUTH_PAGE_UNLOADED = "AUTH_PAGE_UNLOADED";
export const CLEAR_RESEND_MESSAGE = "CLEAR_RESEND_MESSAGE";
export const RESEND_CONFIRMATION_EMAIL = "RESEND_CONFIRMATION_EMAIL";
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const SIGN_UP_NEW_PASSWORD_REQUIRED = "SIGN_UP_NEW_PASSWORD_REQUIRED";
export const CLEAR_FORGOT_PASSWORD = "CLEAR_FORGOT_PASSWORD";
export const LOGGED_IN_USER_DETAILS_REQUEST = "LOGGED_IN_USER_DETAILS_REQUEST";
export const LOGGED_IN_USER_DETAILS_SUCCESS = "LOGGED_IN_USER_DETAILS_SUCCESS";
export const LOGGED_IN_USER_DETAILS_FAILURE = "LOGGED_IN_USER_DETAILS_FAILURE";
export const PRE_LOGIN_REQUEST = "PRE_LOGIN_REQUEST";
export const PRE_LOGIN_SUCCESS = "PRE_LOGIN_SUCCESS";
export const PRE_LOGIN_REDIRECT = "PRE_LOGIN_REDIRECT";
export const LOGOUT_REDIRECT = "LOGOUT_REDIRECT";
export const SET_LOGIN_USER_ROLE_ID = "SET_LOGIN_USER_ROLE_ID";
export const CLEAR_LOGIN_STATE = "CLEAR_LOGIN_STATE";
export const SUBSCRIPTION_ID = "SUBSCRIPTION_ID";
export const MFA_CODE_REQUIRED_TO_LOGIN = "MFA_CODE_REQUIRED_TO_LOGIN";
export const USER_LOGIN_DISABLED = "USER_LOGIN_DISABLED";
export const UPDATE_MFA_CONFIG = "UPDATE_MFA_CONFIG";
