import config from "../config";
import { isAdminUserFn } from "./users";

export const APP_ENVS = {
  DEVELOPMENT: "Development",
  STAGING: "Staging",
  INTERNAL: "Internal",
  PRODUCTION: "Production",
};

export const getMarvinUIEnabled = (auth) => {
  const { features } = auth;
  const env = config.environment;
  const isAdmin = isAdminUserFn()
  if ([APP_ENVS.PRODUCTION, APP_ENVS.INTERNAL].includes(env) && isAdmin) {
    return features && features.Marvin;
  }
  return isAdmin;
};

