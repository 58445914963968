// checks if contains at least one number
export const numberRegex = /\d/;

// checks if contains only string and is a valid full name
export const fullNameRegex = /^[a-zA-Z]{1,}(?: [a-zA-Z]+){0,2}$/;

// checks if the email is valid
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

// checks if at least one special character(all) exists
export const specialCharacterRegex = /[=+^$*.[\]{}()?"!@#%&/,><':;|_~`\\-]/;

// checks if entered input is 8 character long, has at least one uppercase, one lowercase,
// one number and a special character(all)
export const passwordRegex =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+^$*.[\]{}()?"!@#%&/,><':;|_~`\\-])(?=.{8,})/g; //

export const policyNameRegex = /^[a-zA-Z0-9_\-.]{3,128}$/;

export const urlRegex =
  /[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/i;

// checks if all are numbers
export const numbersOnlyRegex = /^\d+$/;